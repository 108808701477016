import React, { createContext } from 'react';
import { IUsePopover } from 'app/hooks/usePopover/types';

interface IDropdownButtonContext extends IUsePopover {}

export const DropdownButtonContext = createContext<
  IDropdownButtonContext | undefined
>(undefined);

export const useDropdownButton = () => {
  const context = React.useContext(DropdownButtonContext);
  if (context === undefined) {
    throw new Error(
      'useDropdownButton must be used within a DropdownButtonContextProvider',
    );
  }
  return context;
};
