import { faker } from '@faker-js/faker';
import { APIResponseParties } from 'api/thirdParties/getPartiesAPI';
import { buildMockTable, getRandomItemFromArray } from 'api/utils';
import { IHeaderItem, ITableRow } from 'app/containers/AbstractTable/types';
import { buildMockFilterData } from 'api/mockFilterData';
import { buildUserResult } from 'api/users/getUsersAPI/mock';

const rowsCount = 10;

const headerItems: IHeaderItem[] = [
  {
    id: 'name_th',
    label: 'Name',
  },
  {
    id: 'email_th',
    label: 'Email',
  },
  {
    id: 'status_th',
    label: 'Status',
  },
  {
    id: 'lastLogin_th',
    label: 'Last log in',
  },
  {
    id: 'admin_th',
    label: 'Admin',
    is_small: 'true',
  },
  {
    id: 'action_th',
    label: '',
    is_small: 'true',
  },
];

const { lorem } = faker;

const buildRows = (count: number): ITableRow => {
  const user = buildUserResult(count, lorem.words());

  return {
    id: user.user_id,
    type: 'row',
    cells: [
      { type: 'text', data: { text: user.name || '' } },
      { type: 'text', data: { text: user.email || '' } },
      {
        type: 'user-status',
        data: {
          status: getRandomItemFromArray(['Pending', 'Active']) || 'Active',
        },
      },
      {
        type: 'date',
        data: { date: user.last_login?.toString(), show_time: true },
      },
      {
        type: 'user-admin-toggle',
        data: {
          user_id: user.user_id,
          is_user_manager: !!user.is_user_manager,
        },
      },
      { type: 'user-actions', data: { user } },
    ],
  };
};

const rows = Array.from(
  Array(Math.floor(Math.random() * rowsCount) + 1).keys(),
).map(buildRows);

const table = buildMockTable(headerItems, rows);

export const mock: APIResponseParties = {
  table,
  page_size: 10,
  page_sizes: [10, 20, 50],
  count: 125,
  page: 1,
  filter_data: buildMockFilterData(),
  legend: {
    items: [],
  },
};
