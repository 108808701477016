import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { request } from 'utils/request';

export interface IPayloadDeleteUser {
  id: string;
}

export const deleteUserAPI: RequestApi<IPayloadDeleteUser, void> = async (
  { id },
  signal,
) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, 300);
    });
  }

  return request(`/users/${id}`, {
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
    },
    signal,
  });
};
