import { API_HOST, AUTH0_AUDIENCE, NODE_ENV } from 'utils/constants';
import { auth0 } from 'utils/auth0';
import * as Sentry from '@sentry/react';
import { getHex } from './common';
import { ResponseError } from 'types/errors';

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

class SentryError extends Error {
  constructor(response: Response, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, SentryError);
    }

    this.name = `Request error`;
    this.message = `API failed with ${response.status} ${response.url}`;
  }
}

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
export function parseJSON(response: Response) {
  if (
    response.status === 204 ||
    response.status === 205 ||
    response.status === 202
  ) {
    return null;
  }
  return response.json();
}

export default async function getNewToken() {
  try {
    const token = await auth0.getTokenSilently({
      authorizationParams: {
        audience: AUTH0_AUDIENCE,
      },
    });

    sessionStorage.setItem('t', token);

    return token;
  } catch (e: any) {
    window.location.reload();
  }
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @param transactionId
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
export function checkStatus(response: Response, transactionId: string) {
  if (
    (response.status >= 200 && response.status < 300) ||
    response.status === 401
  ) {
    return response;
  }

  const user_id = sessionStorage.getItem('user_id') || '';

  const sentryError = new SentryError(response);

  Sentry.captureException(sentryError, {
    tags: {
      curago_transaction_id: transactionId,
      user_id,
      api_url: response.url,
    },
  });

  const error = new ResponseError(response, transactionId);
  error.response = response;
  error.transactionId = transactionId;

  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @param token
 * @param transactionId
 * @return {object}           The response data
 */
export const getResponseWithToken = async (
  url: string,
  token: string,
  transactionId: string,
  options?: IRequestOptions,
): Promise<any> => {
  if (options?.absoluteUrl) {
    return fetch(url, options);
  }

  return fetch(`${API_HOST}${url}`, {
    ...options,
    headers: {
      ...options?.headers,
      Authorization: `Bearer ${token}`,
      'X-CURAGO-USER-TIMEZONE': timezone,
      'X-CURAGO-TRANSACTION-ID': transactionId,
    },
  });
};

interface IRequestOptions extends RequestInit {
  file?: boolean;
  absoluteUrl?: boolean;
  disableParse?: boolean;
}

export async function request(
  url: string,
  options?: IRequestOptions,
): Promise<{} | { err: ResponseError } | any> {
  const token = sessionStorage.getItem('t') || '';
  const transactionId = `tprm-web-app-${getHex(5)}`;
  const fetchResponse = await getResponseWithToken(
    url,
    token,
    transactionId,
    options,
  );

  const response = checkStatus(fetchResponse, transactionId);

  if (response.status === 401 && NODE_ENV !== 'development') {
    return auth0.loginWithRedirect({
      authorizationParams: {
        audience: AUTH0_AUDIENCE,
        redirect_uri: window.location.origin,
      },
    });
  }

  if (options?.disableParse) {
    return response;
  }

  if (options?.file) {
    return response.blob();
  }

  return parseJSON(response);
}
