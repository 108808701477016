import { call, put, takeEvery } from 'redux-saga/effects';
import { actions } from './slice';
import { PayloadAction } from '@reduxjs/toolkit';
import { AbstractDataType, AbstractParams, IGetAbstractData } from './types';
import { actions as actionsRoot } from '../../containers/Root/slice';
import { pendingApiReqState } from './utils';
import { getTotalsPartiesScopesAPI } from 'api/totals/getTotalsPartiesScopesAPI';
import { getTotalsRiskBoardAPI } from 'api/totals/getTotalsRiskBoardAPI';
import { getTotalsEventsAPI } from 'api/totals/getTotalsEventsAPI';
import { getPartiesAPI } from 'api/thirdParties/getPartiesAPI';
import { getPartyAPI } from 'api/thirdParties/getPartyAPI';
import { getRiskDomainsAPI } from 'api/thirdParties/getRiskDomainsAPI';
import { getScopeListAPI } from 'api/scopes/getScopeListAPI';
import { getScopeDataAPI } from 'api/scopes/getScopeDataAPI';
import { getPartyInsightsAPI } from 'api/insights/getPartInsightsAPI';
import { getPartyInsightItemAPI } from 'api/insights/getPartyInsightItemAPI';
import { getPartyDocumentsAPI } from 'api/thirdParties/getPartyDocumentsAPI';
import { getFilesConfigAPI } from 'api/files/getFilesConfig';
import { getScopeOptionsListAPI } from 'api/thirdParties/getScopeOptionsListAPI';
import { getLogoFetchAPI } from 'api/admin/getLogoFetchAPI';
import { getLogoFetchItemAPI } from 'api/admin/getLogoFetchItemAPI';
import { getNotificationCenterPartiesAPI } from 'api/notifications/getNotificationCenterPartiesAPI';
import { getRiskBoardMatrixAPI } from 'api/riskBoard/getRiskBoardMatrixAPI';
import { getRiskBoardMatrixCellAPI } from 'api/riskBoard/getRiskBoardMatrixCellAPI';
import { getRiskBoardMatrixCellGroupsAPI } from 'api/riskBoard/getRiskBoardMatrixCellGroupsAPI';
import { getNewPartyFormAPI } from 'api/thirdParties/getNewPartyFormAPI';
import { getInitScopeDataAPI } from 'api/scopes/getInitScopeDataAPI';
import { deepNormalizeData } from 'utils/normalizeData';
import {
  APIResponseRiskHunter,
  getRiskHunterAPI,
} from 'api/riskHunter/getRiskHunterAPI';
import { getUsersTableAPI } from 'api/users/getUsersTableAPI';

export function* getAbstractDataSaga<T extends AbstractDataType>({
  payload,
}: PayloadAction<IGetAbstractData<T>>) {
  const { id, params } = payload;

  const controller = new AbortController();
  const signal = controller.signal;

  const pendingApiReq = pendingApiReqState[id];

  pendingApiReq?.forEach(c => {
    c.abort();
  });

  pendingApiReq?.push(controller);

  try {
    let data;

    switch (id) {
      case 'totals_parties_scopes':
        data = yield call(getTotalsPartiesScopesAPI, {}, signal);
        break;
      case 'totals_risk_board':
        data = yield call(getTotalsRiskBoardAPI, {}, signal);
        break;
      case 'totals_events':
        data = yield call(getTotalsEventsAPI, {}, signal);
        break;
      case 'parties_list':
        data = yield call(
          getPartiesAPI,
          params as AbstractParams<'parties_list'>,
          signal,
        );
        break;
      case 'notification_center_parties_list':
        data = yield getNotificationCenterPartiesAPI(
          params as AbstractParams<'notification_center_parties_list'>,
          signal,
        );
        break;
      case 'party':
        data = yield call(
          getPartyAPI,
          params as AbstractParams<'party'>,
          signal,
        );
        break;
      case 'party_documents':
        data = yield call(
          getPartyDocumentsAPI,
          params as AbstractParams<'party_documents'>,
          signal,
        );
        break;
      case 'risk_domains':
        data = yield call(
          getRiskDomainsAPI,
          params as AbstractParams<'risk_domains'>,
          signal,
        );
        break;
      case 'scopes_list':
        data = yield call(
          getScopeListAPI,
          params as AbstractParams<'scopes_list'>,
          signal,
        );
        break;
      case 'scope_options_list':
        data = yield call(
          getScopeOptionsListAPI,
          params as AbstractParams<'scope_options_list'>,
          signal,
        );
        break;
      case 'scope_data':
        data = yield call(
          getScopeDataAPI,
          params as AbstractParams<'scope_data'>,
          signal,
        );
        break;
      case 'party_insights':
        data = yield call(
          getPartyInsightsAPI,
          params as AbstractParams<'party_insights'>,
          signal,
        );
        break;
      case 'party_insight_item':
        data = yield call(
          getPartyInsightItemAPI,
          params as AbstractParams<'party_insight_item'>,
          signal,
        );
        break;
      case 'risk_board_matrix':
        data = yield call(
          getRiskBoardMatrixAPI,
          params as AbstractParams<'risk_board_matrix'>,
          signal,
        );
        break;
      case 'risk_board_matrix_cell':
        data = yield getRiskBoardMatrixCellAPI(
          params as AbstractParams<'risk_board_matrix_cell'>,
          signal,
        );
        break;
      case 'risk_board_matrix_cell_groups':
        data = yield getRiskBoardMatrixCellGroupsAPI(
          params as AbstractParams<'risk_board_matrix_cell_groups'>,
          signal,
        );
        break;
      case 'logo_fetch':
        data = yield getLogoFetchAPI(
          params as AbstractParams<'logo_fetch'>,
          signal,
        );
        break;
      case 'logo_fetch_item':
        data = yield getLogoFetchItemAPI(
          params as AbstractParams<'logo_fetch_item'>,
          signal,
        );
        break;
      case 'files_config':
        data = yield getFilesConfigAPI(
          params as AbstractParams<'files_config'>,
          signal,
        );
        break;
      case 'new_party_form':
        data = yield getNewPartyFormAPI(
          params as AbstractParams<'new_party_form'>,
          signal,
        );
        break;
      case 'new_scope_form':
        data = yield getInitScopeDataAPI(
          params as AbstractParams<'new_scope_form'>,
          signal,
        );
        break;
      case 'risk_hunter':
        const res: APIResponseRiskHunter = yield getRiskHunterAPI(
          params as AbstractParams<'risk_hunter'>,
          signal,
        );

        data = {
          ...res,
          dynamic_dimensions: res.dynamic_dimensions.filter(
            dimension => dimension.values.length,
          ),
        };
        break;
      case 'user_list': {
        data = yield getUsersTableAPI(
          params as AbstractParams<'user_list'>,
          signal,
        );
        break;
      }
      default:
        throw new Error('Wrong list id');
    }

    pendingApiReq?.pop();

    yield put(
      actions.getAbstractDataOk({
        id,
        data: deepNormalizeData(data),
        currentParams: params,
      }),
    );
  } catch (e: any) {
    if (e.name === 'AbortError') {
      return;
    }

    yield put(
      actions.getAbstractDataError({
        id,
        error: {
          type: 'block_crash',
          transactionId: e.transactionId,
        },
      }),
    );

    switch (id) {
      case 'totals_risk_board':
      case 'totals_parties_scopes':
        yield put(
          actionsRoot.addDefaultErrorAlert({
            transactionId: e.transactionId,
          }),
        );
        break;
      default:
        yield put(
          actionsRoot.onError({
            type: 'crash',
            transactionId: e.transactionId,
          }),
        );
    }
  }
}

export function* useGetAbstractDataSaga() {
  yield takeEvery(actions.getAbstractData.type, getAbstractDataSaga);
}
