import { IUserActionsCell } from 'app/containers/AbstractTable/types';
import { IMenuOption, MenuButton } from 'app/components/Buttons/MenuButton';
import { useAlerts } from 'app/containers/Root/Alerts/useAlerts';
import { generateRandomId } from 'utils/common';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { deleteUserAPI } from 'api/users/deleteUserApi';
import { useUserManagerContext } from 'app/containers/RootAdmin/UserManagement/context';

export const CellUserActions = ({ data }: IUserActionsCell) => {
  const { addAlert, addDefaultErrorAlert } = useAlerts();

  const { updateUserList } = useUserManagerContext();

  const history = useHistory();

  const handleEdit = () => {
    history.push(
      `/admin/user-management/${data.user.user_id}?${queryString.stringify({
        data: JSON.stringify(data.user),
      })}`,
    );
  };

  const doDeleteUser = async () => {
    try {
      await deleteUserAPI({ id: data.user.user_id });

      setTimeout(async () => await updateUserList(), 1000);
    } catch (e: any) {
      addDefaultErrorAlert(e.transitionId);
    }
  };

  const handleDelete = () => {
    addAlert({
      id: generateRandomId(),
      type: 'default',
      text: `Are you sure you want to remove ${data.user.name}?`,
      actions: [
        { title: 'Cancel', type: 'secondarySmall' },
        { title: 'Ok', type: 'default', onClick: doDeleteUser },
      ],
    });
  };

  const actions: IMenuOption[] = [
    { label: 'Edit', action: handleEdit, icon: 'edit' },
    { label: 'Delete', action: handleDelete, icon: 'remove' },
  ];

  return <MenuButton options={actions} />;
};
