import { faker } from '@faker-js/faker';
import {
  IAbstractTableData,
  IHeaderItem,
  ITableRow,
} from 'app/containers/AbstractTable/types';

const { random, datatype } = faker;

export const getRandomNumberWithZero = () =>
  datatype.boolean() ? parseInt(random.numeric(), 10) : 0;

export const apiDelay = 300;

export const buildMockTable: (
  headerItems: IHeaderItem[],
  rows: ITableRow[],
) => IAbstractTableData = (headerItems, rows) => {
  return {
    header: {
      items: headerItems,
    },
    rows,
    order_column: headerItems[0] ? headerItems[0].id : '',
    order_direction: -1,
  };
};

export const getRandomItemFromArray = <T extends string>(
  arr: T[],
): T | undefined => {
  if (arr.length === 0) {
    return undefined; // Return undefined if the array is empty
  }

  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
};
