import React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Global } from '@emotion/react';
import { globalStyles } from 'styles/global-styles';
import { useRoot } from 'app/containers/Root/useRoot';
import { AppLoader } from './containers/Root/AppLoader';
import { ThemeProvider } from '@mui/material/styles';
import { RootLayout } from './containers/Root';
import { Alerts } from './containers/Root/Alerts';
import { SnackbarProvider } from 'notistack';
import { useEffectOnMount } from 'utils/useEffectOnMount';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

export const App = () => {
  const { i18n } = useTranslation();

  const { initApp, theme } = useRoot();

  useEffectOnMount(() => {
    initApp();
  });

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <BrowserRouter>
            <Helmet
              titleTemplate="TPRM"
              defaultTitle="TPRM"
              htmlAttributes={{ lang: i18n.language }}
            >
              <meta name="description" content="TPRM" />
            </Helmet>
            <RootLayout />
            <Alerts />
            <AppLoader />
            <Global styles={globalStyles} />
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
};
