import { TableCellProps } from 'app/containers/AbstractTable/types';
import { useTableRow } from 'app/containers/AbstractTable/TableRow/TableRowContext';
import { CellText } from './CellText';
import { CellTextList } from './CellTextList';
import { CellNameWithAvatar } from './CellNameWithAvatar';
import { CellDate } from './CellDate';
import { CellTextWithShowMore } from './CellTextWithShowMore';
import { CellTextWithTooltip } from './CellTextWithTooltip';
import { CellStatusIcon } from './CellStatusIcon';
import { CellTagsList } from './CellTagsList';
import { CellScopesList } from './CellScopesList';
import { CellShowMore } from './CellShowMore';
import { CellRiskDomain } from './CellRiskDomain';
import { CellInsightIndicator } from './CellInsightIndicator';
import { CellInsightGroupIndicator } from './CellInsightGroupIndicator';
import { CellExpandedState } from './CellExpandedState';
import { CellListWithShowMore } from './CellListWithShowMore';
import { CellInsightTypeIcon } from './CellInsightTypeIcon';
import { CellUser } from './CellUser';
import { CellEventTypeIcon } from './CellEventTypeIcon';
import { CellEventTitle } from './CellEventTitle';
import { CellInsightGroupCountWithIndicator } from './CellInsightGroupCountWithIndicator';
import { CellDocumentStatus } from 'app/containers/AbstractTable/TableCell/TableCellBody/CellDocumentStatus';
import { CellDownloadWithSignUrl } from 'app/containers/AbstractTable/TableCell/TableCellBody/CellDownloadWithSignUrl';
import { CellUserStatus } from 'app/containers/AbstractTable/TableCell/TableCellBody/CellUserStatus';
import { CellUserAdminToggle } from 'app/containers/AbstractTable/TableCell/TableCellBody/CellUserAdminToggle';
import { CellUserActions } from 'app/containers/AbstractTable/TableCell/TableCellBody/CellUserActions';

export const TableCellBody = ({ cell }: TableCellProps) => {
  const { hideCell } = useTableRow();

  if (hideCell === cell.type) {
    return null;
  }

  switch (cell.type) {
    case 'text':
      return <CellText {...cell} />;
    case 'text-list':
      return <CellTextList {...cell} />;
    case 'name-with-avatar':
      return <CellNameWithAvatar {...cell} />;
    case 'date':
      return <CellDate {...cell} />;
    case 'text-with-show-more':
      return <CellTextWithShowMore {...cell} />;
    case 'text-with-tooltip':
      return <CellTextWithTooltip {...cell} />;
    case 'status-icon':
      return <CellStatusIcon {...cell} />;
    case 'tags-list':
      return <CellTagsList {...cell} />;
    case 'scopes-list':
      return <CellScopesList {...cell} />;
    case 'show-more':
      return <CellShowMore />;
    case 'risk-domain':
      return <CellRiskDomain {...cell} />;
    case 'insight-indicator':
      return <CellInsightIndicator {...cell} />;
    case 'insight-group-indicator':
      return <CellInsightGroupIndicator {...cell} />;
    case 'insight-type-icon':
      return <CellInsightTypeIcon {...cell} />;
    case 'expanded-state':
      return <CellExpandedState />;
    case 'list-with-show-more':
      return <CellListWithShowMore {...cell.data} />;
    case 'user':
      return <CellUser {...cell} />;
    case 'event-type-icon':
      return <CellEventTypeIcon {...cell} />;
    case 'event-title':
      return <CellEventTitle {...cell} />;
    case 'insight-group-count-with-indicator':
      return <CellInsightGroupCountWithIndicator {...cell} />;
    case 'document_status':
      return <CellDocumentStatus {...cell} />;
    case 'download_document':
      return <CellDownloadWithSignUrl {...cell} />;
    case 'user-status':
      return <CellUserStatus {...cell} />;
    case 'user-admin-toggle':
      return <CellUserAdminToggle {...cell} />;
    case 'user-actions':
      return <CellUserActions {...cell} />;
    case 'empty':
    default:
      return null;
  }
};
