import React from 'react';

import {
  Chip as MUIChip,
  ChipProps as MUIChipProps,
  styled,
} from '@mui/material';
import { Icon } from '../Icon';
import { IconsMapType } from '../Icon/Icons';
import { Tooltip } from '../Tooltip';

const StyledChip = styled(MUIChip)``;

export interface IChipProps extends MUIChipProps {
  iconName?: keyof IconsMapType;
  iconColor?: string;
  tooltip?: string;
}

export const Chip = (props: IChipProps) => {
  const { iconName, iconColor, tooltip } = props;

  return (
    <Tooltip title={tooltip} disableHoverListener={!tooltip} arrow>
      <StyledChip
        icon={
          iconName ? (
            <Icon
              color={iconColor}
              name={iconName}
              m="0 0.5rem 0 0"
              width="1.2rem"
              height="1.2rem"
            />
          ) : undefined
        }
        {...props}
      />
    </Tooltip>
  );
};
