import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { Progress } from 'app/components/Progress';

interface LoadingProps {
  loading?: boolean;
  overlay?: boolean;
  background?: boolean;
  opacity?: boolean;
  collapsed?: boolean;
  p?: string;
  m?: string;
  size?: string;
}

export const StyledLoadingOverlay = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'loading' &&
    prop !== 'overlay' &&
    prop !== 'background' &&
    prop !== 'opacity' &&
    prop !== 'collapsed' &&
    prop !== 'm' &&
    prop !== 'p',
})<LoadingProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.3s;

  ${({ p = 0, m = 0 }) => `
      padding: ${p};
      margin: ${m};
  `}

  ${({ overlay }) =>
    overlay &&
    `
      position: absolute;
      top:0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index:9;
  `}

  ${({ loading, opacity = 1 }) =>
    loading
      ? `
      opacity: ${opacity};
      pointer-events: all;
      `
      : `
      opacity: 0;
      pointer-events: none;
  `}
`;

export const Loading: FC<LoadingProps> = props => {
  return (
    <StyledLoadingOverlay {...props}>
      <Progress type="circular" size={props.size} />
    </StyledLoadingOverlay>
  );
};
