import { styled } from '@mui/material/styles';
import {
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
} from '@mui/material';
import { WithTestID } from 'types/common';

export type SwitchProps = MuiSwitchProps &
  WithTestID & {
    readonly?: boolean;
  };

export const StyledSwitch = styled(MuiSwitch, {
  shouldForwardProp: prop => prop !== 'readonly',
})<SwitchProps>``;

export const Switch = (props: SwitchProps) => {
  return <StyledSwitch id={props.test_id} {...props} />;
};
