import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { request } from 'utils/request';
import { IUser } from 'types/user';

export type IPayloadUpdateUser = { id: string } & Partial<IUser>;

export const updateUserAPI: RequestApi<IPayloadUpdateUser, void> = async (
  { id, ...payload },
  signal,
) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, 300);
    });
  }

  return request(`/users/${id}`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(payload),
    signal,
  });
};
