import { faker } from '@faker-js/faker';
import { APIResponseUsers } from 'api/users/getUsersAPI/index';
import { IUser } from 'types/user';

const { datatype, internet, name, date, number } = faker;

const count = 10;

export const buildUserResult = (num: number, query: string): IUser => {
  if (num === 0) {
    return {
      user_id: datatype.uuid(),
      name: name.fullName(),
      email: `${query}`,
      last_login: date.past().toDateString(),
      logins_count: number.int({ min: 0 }),
      user_metadata: {},
      is_user_manager: datatype.boolean(),
    };
  }
  return {
    user_id: datatype.uuid(),
    name: name.fullName(),
    email: `${query}${internet.email()}`,
    last_login: date.past().toDateString(),
    logins_count: number.int({ min: 0 }),
    is_user_manager: datatype.boolean(),
    user_metadata: {},
  };
};

export const results = (query: string) =>
  Array.from(Array(Math.floor(Math.random() * count) + 1).keys()).map(count =>
    buildUserResult(count, query),
  );

export const mock: (query: string) => APIResponseUsers = query => ({
  results: results(query),
  page_size: 10,
  page_sizes: [10, 20, 50],
  count: 125,
  page: 1,
  legend: {
    items: [],
  },
});
