import { useSelector } from 'react-redux';
import { selectRoot } from './selectors';
import { PermissionsMapType, UsePermissionsType } from './types';

export const usePermissions: UsePermissionsType = () => {
  const { permissions, current_user } = useSelector(selectRoot);

  return permissions.reduce<PermissionsMapType>(
    (acc, flag) => {
      if (flag === 'create:third_party') {
        return { ...acc, createParty: true };
      }

      if (flag === 'create:scope') {
        return { ...acc, createScope: true };
      }

      if (flag === 'read:reports') {
        return { ...acc, reports: true };
      }

      if (flag === 'read:rh') {
        return { ...acc, aggregatorView: true };
      }

      return { ...acc };
    },
    {
      createParty: false,
      createScope: false,
      reports: false,
      aggregatorView: false,
      followParty: true,
      asd: true,
      notifications: true,
      isAdmin: !!current_user?.is_user_manager,
    },
  );
};
