import { RequestApi } from 'api/types';
import { SearchResponse } from 'app/hooks/useSearch/types';
import { USE_MOCK } from 'utils/constants';
import { apiDelay } from 'api/utils';
import { request } from 'utils/request';
import { mock } from 'api/searchAPI/mock';
import { IPageParams } from 'app/hooks/usePageParams/types';
import { stringifyParams } from 'app/hooks/useGetAbstractData/utils';

export type APIPayloadSearch = IPageParams;

export interface APIResponseSearch extends SearchResponse {}

export const searchAPI: RequestApi<
  APIPayloadSearch,
  APIResponseSearch
> = async (payload, signal) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(mock(payload?.query || ''));
      }, apiDelay);
    });
  }

  return request(`/search/?${stringifyParams(payload)}`, { signal });
};
