import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { apiDelay } from 'api/utils';
import { request } from 'utils/request';
import {
  IAbstractFilterData,
  IAbstractLegend,
  IAbstractPageData,
  IAbstractTable,
} from 'app/hooks/useGetAbstractData/typesOutputConfig';
import { mock } from 'api/users/getUsersTableAPI/mock';

export interface APIResponseUsersTable
  extends IAbstractTable,
    IAbstractFilterData,
    IAbstractPageData,
    IAbstractLegend {}

export const getUsersTableAPI: RequestApi<{}, APIResponseUsersTable> = async (
  payload,
  signal,
) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(mock);
      }, apiDelay);
    });
  }

  return request(`/users/list`, { signal });
};
