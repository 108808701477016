import React, { FC, ReactNode } from 'react';

import { styled } from '@mui/material/styles';
import { Button, ButtonProps } from 'app/components/Buttons/Button';
import { Icon } from 'app/components/Icon';
import { Popover } from 'app/components/Popover';
import { usePopover } from 'app/hooks/usePopover';
import { MuiProps } from 'types/common';
import { DropdownButtonContext } from 'app/components/Buttons/DropdownButton/Context';
import { IconButton } from 'app/components/Buttons/IconButton';

export type DropdownButtonProps = ButtonProps &
  MuiProps & {
    children?: ReactNode;
    label: ReactNode;
    transformOrigin?: {
      vertical: 'top' | 'bottom';
      horizontal: 'left' | 'right';
    };
    anchorOrigin?: {
      vertical: 'top' | 'bottom';
      horizontal: 'left' | 'right';
    };
  };

interface StyledButtonProps {
  open?: boolean;
  badged?: boolean;
}

export const StyledButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'open',
})<StyledButtonProps>`
  text-transform: none;
  white-space: nowrap;
  gap: 0.8rem;

  border: 1px solid transparent;
  border-radius: ${({ open }) => (open ? '6px 6px 0px 0px' : '6px')};
  background: ${({ theme }) => theme.palette.background.paper};

  ${({ open, theme }) =>
    open &&
    `
      box-shadow: ${theme.shadows[2]};
      border: 1px solid ${theme.palette.grey[300]};
      border-bottom: 1px solid transparent;
    `};

  .MuiButtonBase-root-MuiMenuItem-root + .MuiDivider-root {
    margin-top: 0;
    margin-bottom: 0;
    opacity: 1;
  }
`;

export const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    border-radius: 0 6px 6px 6px;

    .MuiDivider-root {
      margin-top: 0;
      margin-bottom: 0;
      opacity: 1;
    }
  }
`;

export const DropdownButton: FC<DropdownButtonProps> = ({
  children,
  label,
  transformOrigin = {
    vertical: 'top',
    horizontal: 'left',
  },
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
  ...rest
}) => {
  const { anchorEl, openMenu, closeMenu } = usePopover();

  return (
    <DropdownButtonContext.Provider value={{ anchorEl, openMenu, closeMenu }}>
      <IconButton {...rest} onClick={openMenu}>
        <Icon name="more_vert" />
      </IconButton>
      <StyledPopover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {children}
      </StyledPopover>
    </DropdownButtonContext.Provider>
  );
};
