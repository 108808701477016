/**
 *
 * AlertError
 *
 */

import React from 'react';
import { IAlertDefault } from './types';
import { AlertTitle } from './AlertTitle';
import { useDispatch } from 'react-redux';
import { actions as alertActions } from '../slice';
import { Box, Dialog, DialogActions, DialogContent } from '@mui/material';
import { Typography } from 'app/components/Typography';
import { TextWithMods } from 'app/components/TextWithMods';
import { AlertActionButton } from 'app/containers/Root/Alerts/AlertActionButton';

interface IAlertDefaultProps extends IAlertDefault {}

export function AlertDefault(props: IAlertDefaultProps) {
  const { id, title, text, markUpText, mods, actions } = props;

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(alertActions.removeAlert(props));
  };

  return (
    <Dialog id={id} open={true} onClose={handleClose} fullWidth maxWidth="sm">
      <AlertTitle onClose={handleClose}>{title}</AlertTitle>
      <DialogContent>
        <Box display="flex">
          {markUpText ? (
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{ __html: text }}
            />
          ) : (
            <Typography style={{ whiteSpace: 'pre-line' }} variant="body2">
              <TextWithMods mods={mods || []}>{text}</TextWithMods>
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {actions?.map((action, n) => (
          <AlertActionButton key={n} handleClose={handleClose} {...action} />
        ))}
      </DialogActions>
    </Dialog>
  );
}
