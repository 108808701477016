/**
 *
 * usePopover
 *
 */

import { IUsePopover } from './types';
import React from 'react';

export function usePopover(): IUsePopover {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return { anchorEl, openMenu, closeMenu };
}
