import React, { FC, ReactNode } from 'react';
import { ButtonProps } from 'app/components/Buttons/Button';
import { DropdownButton } from 'app/components/Buttons/DropdownButton';
import { Icon } from 'app/components/Icon';
import { IconsMapType } from 'app/components/Icon/Icons';
import { Typography } from 'app/components/Typography';
import { MuiProps } from 'types/common';
import { MenuItem } from 'app/components/Menu/MenuItem';
import { useDropdownButton } from 'app/components/Buttons/DropdownButton/Context';

export interface IMenuOption {
  label?: string;
  icon?: keyof IconsMapType;
  disabled?: boolean;
  action: (params?: any) => void;
}

export type MenuButtonProps = ButtonProps &
  MuiProps & {
    options: IMenuOption[];
    children?: ReactNode;
  };

const Option = ({ action, label, icon, disabled }: IMenuOption) => {
  const { closeMenu } = useDropdownButton();

  const handleClickMenu = () => {
    action();
    closeMenu();
  };

  return (
    <MenuItem onClick={handleClickMenu} disabled={disabled}>
      {icon && (
        <Icon m="0 .5rem 0 0" width="1.2rem" height="1.2rem" name={icon} />
      )}
      <Typography variant="body2">{label}</Typography>
    </MenuItem>
  );
};

export const MenuButton: FC<MenuButtonProps> = ({
  children,
  options,
  ...rest
}) => {
  return (
    <DropdownButton label={children} {...rest}>
      {options.map(props => (
        <Option key={props.label} {...props} />
      ))}
    </DropdownButton>
  );
};
