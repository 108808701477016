/**
 *
 * AlertActionButton
 *
 */

import { Button } from 'app/components/Buttons/Button';
import { AlertAction } from './types';

type AlertActionButtonProps = AlertAction & {
  handleClose: () => void;
};

export function AlertActionButton(props: AlertActionButtonProps) {
  const { title, type, onClick, handleClose } = props;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }

    handleClose();
  };

  switch (type) {
    case 'default':
      return (
        <Button variant="contained" onClick={handleClick}>
          {title}
        </Button>
      );
    case 'secondarySmall':
      return (
        <Button size="small" color="inherit" underline onClick={handleClick}>
          {title}
        </Button>
      );
    default:
      return null;
  }
}
