import { IUserAdminToggleCell } from 'app/containers/AbstractTable/types';
import { Box } from 'app/components/Box';
import { useAlerts } from 'app/containers/Root/Alerts/useAlerts';
import { Switch, SwitchProps } from 'app/components/Inputs/Switch';
import { useImmer } from 'use-immer';
import { Loading } from 'app/components/Loading';
import { useEffect } from 'react';
import { updateUserAPI } from 'api/users/updateUserAPI';
import { useUserManagerContext } from 'app/containers/RootAdmin/UserManagement/context';

type CellUserAdminToggleState = {
  loading?: boolean;
  checked?: boolean;
};

export const CellUserAdminToggle = ({ data }: IUserAdminToggleCell) => {
  const [{ checked, loading }, setState] = useImmer<CellUserAdminToggleState>(
    {},
  );

  const { updateUserList } = useUserManagerContext();

  useEffect(() => {
    setState({ checked: data.is_user_manager });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.is_user_manager]);

  const { addDefaultErrorAlert } = useAlerts();

  const handleChange: SwitchProps['onChange'] = async event => {
    try {
      setState(draft => {
        draft.loading = true;
      });

      await updateUserAPI({
        id: data.user_id,
        is_user_manager: event.target.checked,
      });

      setTimeout(async () => await updateUserList(), 1000);

      setState(draft => {
        draft.checked = !draft.checked;
      });
    } catch (e: any) {
      addDefaultErrorAlert(e.transactionId);
    } finally {
      setState(draft => {
        draft.loading = false;
      });
    }
  };

  return (
    <Box position="relative">
      <Switch onChange={handleChange} checked={checked} />
      <Loading size="2rem" loading={loading} overlay />
    </Box>
  );
};
