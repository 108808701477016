import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { actions, reducer, sliceKey } from './slice';
import { selectRoot } from './selectors';
import { rootSaga } from './saga';
import { UseRootType } from './types';
import { createTheme } from '@mui/material/styles';
import { useMemo, useState } from 'react';
import { PaletteMode } from '@mui/material';
import { getDesignTokens } from 'styles/theme/defaultTheme';
import {
  reducer as useGetAbstractDataReducer,
  sliceKey as useGetAbstractDataSliceKey,
} from 'app/hooks/useGetAbstractData/slice';

import {
  reducer as addScopeReducer,
  sliceKey as addScopeSliceKey,
} from 'app/containers/RootScope/AddScope/slice';

import {
  reducer as notificationsReducer,
  sliceKey as notificationsSliceKey,
} from './Notifications/slice';

import { notificationsSaga } from './Notifications/saga';

export const useRoot: UseRootType = () => {
  useInjectSaga({ key: sliceKey, saga: rootSaga });
  useInjectReducer({ key: sliceKey, reducer: reducer });

  useInjectSaga({ key: notificationsSliceKey, saga: notificationsSaga });
  useInjectReducer({
    key: notificationsSliceKey,
    reducer: notificationsReducer,
  });

  useInjectReducer({
    key: useGetAbstractDataSliceKey,
    reducer: useGetAbstractDataReducer,
  });

  useInjectReducer({
    key: addScopeSliceKey,
    reducer: addScopeReducer,
  });

  const [mode, setMode] = useState<PaletteMode>('light');

  const toggleColorMode = () => {
    setMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'));
  };

  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  const state = useSelector(selectRoot);
  const dispatch = useDispatch();

  const handleLogout = () => dispatch(actions.doLogout());

  const initApp = () => dispatch(actions.doInit());

  const toggleFullScreenMode = (fullsScreenMode: boolean) => {
    dispatch(actions.doToggleFullScreenMode(fullsScreenMode));
  };

  const changeLanguage = (lang: string) => {
    dispatch(actions.doChangeLanguage(lang));
  };

  return {
    ...state,
    handleLogout,
    initApp,
    toggleFullScreenMode,
    changeLanguage,
    toggleColorMode,
    theme,
  };
};
