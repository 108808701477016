import { IUserStatusCell } from 'app/containers/AbstractTable/types';
import { Chip } from 'app/components/Chip';

export const CellUserStatus = ({ data }: IUserStatusCell) => {
  switch (data.status) {
    case 'Active':
      return <Chip label={data.status} color="info" />;
    case 'Pending':
      return <Chip label={data.status} color="warning" />;
    default:
      return null;
  }
};
