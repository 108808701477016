import { IDateCell } from 'app/containers/AbstractTable/types';
import moment from 'moment';
import { DATE_FORMAT, DATE_FORMAT_WITH_HOURS } from 'utils/constants';
import { Typography } from '@mui/material';

export const CellDate = ({ data }: IDateCell) => {
  const { date, show_time } = data;

  if (!date) {
    return (
      <Typography variant="body2" noWrap>
        -
      </Typography>
    );
  }

  return (
    <Typography variant="body2" noWrap>
      {moment(date).format(show_time ? DATE_FORMAT_WITH_HOURS : DATE_FORMAT)}
    </Typography>
  );
};
